// export const environment = {
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/cycrestaurante%2f',
//   idShop:73,
//   nginx:'http://food.loxafree.com/',
//   empresa:'cycrestaurante'
// };
// export const environment = {
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/equimec2%2F',
//   idShop:2,
//   nginx:'equimec',
//   empresa:'equimec2'
// };
// export const environment = {
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/demorestaurante%2f',
//   idShop:46,
//   nginx:'http://food.loxafree.com/',
//   empresa:'demorestaurante'
// };

// export const environment = {
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/mywok%2f',
//   idShop:71,
//   nginx:'maywok.loxafree.com',
//   empresa:'mywok'
// };

// export const environment = {
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/demo%2f',
//   idShop:1,
//   nginx:'Demo',
//   empresa:'demo'
// };

// export const environment = {
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/alaska%2f',
//   idShop:68,
//   empresa:'alaska'
  
// };
// export const environment = {
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/electrocomercialryr%2F',
//   idShop:64,
//   nginx:'electrocomercialryr.com',
//   empresa:'electrocomercialryr'
// };

// export const environment = {
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/importadoraloayza%2F',
//   idShop:74,
//   empresa:'importadoraloayza'
// };

// export const environment = {
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/netto%2F',
//   idShop:75,
//   empresa:'netto'
// };

// export const environment = {
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/chacarero%2f',
//   idShop:72,
//   nginx:'http://chacarero.loxafree.com',
//   empresa:'chacarero'
// };

export const environment = {
  production: true,
  firebaseConfig : {
    apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
    authDomain: "softpymes-webshop.firebaseapp.com",
    projectId: "softpymes-webshop",
    storageBucket: "softpymes-webshop.appspot.com",
    messagingSenderId: "609230356232",
    appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
    measurementId: "G-26PY63ZLHW"
  },
  firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/djautomotriz%2f',
  idShop:70,
  nginx:'http://www.djmautomotriz.com/',
  empresa:'djautomotriz',
  sistema: 'djautomotriz'
};
// export const environment = {
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/loskombos%2F',
//   idShop:12,
//   nginx:'loskombos.com',
//   empresa:'loskombos'
// };
// export const environment = {
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/mariaypepito%2f',
//   idShop:66,
//   nginx:'pepitoymaria.com',
//   empresa:'mariaypepito'
// };
// export const environment = {
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/alaska%2f',
//   idShop:68,
//   empresa:'alaska'
  
// };

// export const environment = {
//     production: true,
//     firebaseConfig : {
//       apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//       authDomain: "softpymes-webshop.firebaseapp.com",
//       projectId: "softpymes-webshop",
//       storageBucket: "softpymes-webshop.appspot.com",
//       messagingSenderId: "609230356232",
//       appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//       measurementId: "G-26PY63ZLHW"
//     },
//     firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/decorfree%2F',
//     idShop:51,
//     empresa:'decorfree'
//   };

// export const environment = {
//     production: true,
//     firebaseConfig : {
//       apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//       authDomain: "softpymes-webshop.firebaseapp.com",
//       projectId: "softpymes-webshop",
//       storageBucket: "softpymes-webshop.appspot.com",
//       messagingSenderId: "609230356232",
//       appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//       measurementId: "G-26PY63ZLHW"
//     },
//     firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/dicoaltor%2F',
//     idShop:41,
//     nginx:'dicoaltor.com',
//     empresa:'dicoaltor'
//   };

  // export const environment = {
  //   production: true,
  //   firebaseConfig : {
  //     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
  //     authDomain: "softpymes-webshop.firebaseapp.com",
  //     projectId: "softpymes-webshop",
  //     storageBucket: "softpymes-webshop.appspot.com",
  //     messagingSenderId: "609230356232",
  //     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
  //     measurementId: "G-26PY63ZLHW"
  //   },
  //   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/deysmar%2F',
  //   idShop:3,
  //   nginx:'deysmar.com'
  // };

  // export const environment = {
  //   production: true,
  //   firebaseConfig : {
  //     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
  //     authDomain: "softpymes-webshop.firebaseapp.com",
  //     projectId: "softpymes-webshop",
  //     storageBucket: "softpymes-webshop.appspot.com",
  //     messagingSenderId: "609230356232",
  //     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
  //     measurementId: "G-26PY63ZLHW"
  //   },
  //   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/fccelulares%2f',
  //   idShop:67,
  //   nginx:'fccelulares.com.ec',
  //   empresa:'fccelulares'
  // };

  // export const environment = {
  //   production: true,
  //   firebaseConfig : {
  //     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
  //     authDomain: "softpymes-webshop.firebaseapp.com",
  //     projectId: "softpymes-webshop",
  //     storageBucket: "softpymes-webshop.appspot.com",
  //     messagingSenderId: "609230356232",
  //     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
  //     measurementId: "G-26PY63ZLHW"
  //   },
  //   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/dicoaltor%2F',
  //   idShop:41,
  //   nginx:'dicoaltor.com',
  //   empresa:'dicoaltor'
  // };

  // export const environment = {
  //   production: true,
  //   firebaseConfig : {
  //     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
  //     authDomain: "softpymes-webshop.firebaseapp.com",
  //     projectId: "softpymes-webshop",
  //     storageBucket: "softpymes-webshop.appspot.com",
  //     messagingSenderId: "609230356232",
  //     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
  //     measurementId: "G-26PY63ZLHW"
  //   },
  //   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/loskombos%2F',
  //   idShop:12,
  //   nginx:'loskombos.com',
  //   empresa:'loskombos'
  // };